import { ref, onUnmounted } from 'vue'
import { useVibration } from '@st/use/composables'

interface ConfettoParams {
  outer: HTMLDivElement
  inner: HTMLDivElement
  x: number
  y: number
  dx: number
  dy: number
  theta: number
  dTheta: number
  splineX: number[]
  splineY: number[]
  axis: string
  frame: number
  update: (containerHeight: number, delta: number) => boolean
}

/**
 * Параметры:
 * - colors?: string[] - массив hex (или других) цветов. По умолчанию установлен пресет, если его удалить, то цвета выбираются рандомно.
 * - speedMin?: минимальная скорость падения
 * - speedMax?: максимальная скорость падения
 * - spread?: интервал (мс) между волнами конфетти
 * - containerEl?: если нужно рендерить конфетти в конкретном HTMLElement (задает автоматически position: relative; overflow: hidden;)
 *   (иначе создаём на весь экран)
 */
export function useConfetti({
  colors = ['#B5744E', '#50C68D', '#A68AD3', '#D3B154'],
  speedMin = 0.13,
  speedMax = 0.18,
  spread = 80,
  containerEl,
}: {
  colors?: string[]
  speedMin?: number
  speedMax?: number
  spread?: number
  containerEl?: HTMLElement | null
} = {}) {
  const vibrate = useVibration()
  onUnmounted(() => vibrate())

  let isCustomContainer = !!containerEl
  let container: HTMLElement | null = null

  let frameId: number | null = null
  let timerId: ReturnType<typeof setTimeout> | null = null

  const confetti: ConfettoParams[] = []
  const isRunning = ref(false)

  const { random, cos, sin, PI } = Math
  const PI2 = PI * 2

  const sizeMin = 3
  const sizeMax = 12 - sizeMin
  const eccentricity = 10
  const deviation = 100
  const dxThetaMin = -0.1
  const dxThetaMax = 0.2
  const dThetaMin = 0.4
  const dThetaMax = 0.3

  // Если массив с цветами не передан или пуст, будем генерировать рандомный цвет
  function randomColor() {
    return `rgb(
      ${Math.floor(256 * random())},
      ${Math.floor(256 * random())},
      ${Math.floor(256 * random())}
    )`
  }

  /**
   * Функция для получения цвета конфетти:
   * 1) Если есть массив colors и он не пуст, берём случайный цвет из него.
   * 2) Иначе возвращаем случайный цвет RGB.
   */
  function pickConfettiColor(): string {
    if (colors && colors.length > 0) {
      const index = Math.floor(random() * colors.length)
      return colors[index]
    }
    return randomColor()
  }

  function interpolation(a: number, b: number, t: number) {
    return ((1 - cos(PI * t)) / 2) * (b - a) + a
  }

  function createPoisson() {
    const radius = 1 / eccentricity
    const radius2 = radius + radius
    const domain = [radius, 1 - radius]
    let measure = 1 - radius2
    const spline = [0, 1]

    while (measure > 0) {
      const dart = measure * random()
      let accum = 0
      for (let i = 0; i < domain.length; i += 2) {
        const a = domain[i]
        const b = domain[i + 1]
        const interval = b - a
        if (dart < accum + interval) {
          spline.push(a + (dart - accum))
          break
        }
        accum += interval
      }

      const x = spline[spline.length - 1]
      const left = x - radius
      const right = x + radius

      for (let j = domain.length - 1; j > 0; j -= 2) {
        const a = domain[j - 1]
        const b = domain[j]
        if (a >= left && a < right) {
          if (b > right) {
            domain[j - 1] = right
          } else {
            domain.splice(j - 1, 2)
          }
        } else if (a < left && b > left) {
          if (b <= right) {
            domain[j] = left
          } else {
            domain.splice(j, 0, left, right)
          }
        }
      }

      measure = 0
      for (let k = 0; k < domain.length; k += 2) {
        measure += domain[k + 1] - domain[k]
      }
    }

    return spline.sort()
  }

  function createConfetto(): ConfettoParams {
    if (!container) {
      throw new Error('No container found for confetti!')
    }

    const { width } = container.getBoundingClientRect()

    const outer = document.createElement('div')
    const inner = document.createElement('div')
    outer.appendChild(inner)

    const outerStyle = outer.style
    const innerStyle = inner.style

    outerStyle.position = 'absolute'
    outerStyle.perspective = '50px'

    const w = sizeMin + sizeMax * random()
    const h = sizeMin + sizeMax * random()
    outerStyle.width = `${w}px`
    outerStyle.height = `${h}px`
    outerStyle.transform = `rotate(${360 * random()}deg)`

    innerStyle.width = '100%'
    innerStyle.height = '100%'
    // Выбираем цвет (либо из массива, либо рандомный)
    innerStyle.backgroundColor = pickConfettiColor()

    const axis = `rotate3D(${cos(360 * random())},${cos(360 * random())},0,`
    const theta = 360 * random()
    const dTheta = dThetaMin + dThetaMax * random()
    innerStyle.transform = `${axis}${theta}deg)`

    const x = width * random()
    const y = -deviation

    const dx = sin(dxThetaMin + dxThetaMax * random())
    const dy = speedMin + (speedMax - speedMin) * random()

    outerStyle.left = `${x}px`
    outerStyle.top = `${y}px`

    const splineX = createPoisson()
    const splineY: number[] = []
    for (let i = 1; i < splineX.length - 1; i += 1) {
      splineY[i] = deviation * random()
    }
    const randomDeviation = deviation * random()
    splineY[0] = randomDeviation
    splineY[splineX.length - 1] = randomDeviation

    let frame = 0

    function update(containerHeight: number, delta: number) {
      frame += delta
      const newX = x + dx * frame
      const newY = y + dy * frame
      const newTheta = theta + dTheta * frame

      let phi = (frame % 7777) / 7777
      let i = 0
      let j = 1
      while (phi >= splineX[j]) {
        i = j
        j += 1
      }

      const rho = interpolation(
        splineY[i],
        splineY[j],
        (phi - splineX[i]) / (splineX[j] - splineX[i]),
      )
      phi *= PI2

      outerStyle.left = `${newX + rho * cos(phi)}px`
      outerStyle.top = `${newY + rho * sin(phi)}px`
      innerStyle.transform = `${axis}${newTheta}deg)`

      return newY > containerHeight + deviation
    }

    return {
      outer,
      inner,
      x,
      y,
      dx,
      dy,
      theta,
      dTheta,
      axis,
      splineX,
      splineY,
      frame,
      update,
    }
  }

  function cleanup() {
    if (container) {
      if (!isCustomContainer && container.parentNode) {
        container.parentNode.removeChild(container)
      } else {
        while (confetti.length > 0) {
          const c = confetti.pop()
          if (c && container.contains(c.outer)) {
            container.removeChild(c.outer)
          }
        }
      }
    }
    container = null
    confetti.splice(0, confetti.length)
    // eslint-disable-next-line no-use-before-define
    ;(animate as any).prev = undefined
  }

  function animate(timestamp: number) {
    const prevTimestamp = (animate as any).prev
    const delta = prevTimestamp ? timestamp - prevTimestamp : 0
    ;(animate as any).prev = timestamp

    if (!container) return
    const { height } = container.getBoundingClientRect()

    for (let i = confetti.length - 1; i >= 0; i -= 1) {
      const c = confetti[i]
      const isOut = c.update(height, delta)
      if (isOut) {
        container.removeChild(c.outer)
        confetti.splice(i, 1)
      }
    }

    if (timerId || confetti.length) {
      frameId = requestAnimationFrame(animate)
      return
    }
    cleanup()
  }

  function addConfetto(count = 0) {
    const confettoItem = createConfetto()
    confetti.push(confettoItem)
    container?.appendChild(confettoItem.outer)

    timerId = setTimeout(() => {
      addConfetto(count + 1)
    }, spread * random())
  }

  function startConfettiAnimation() {
    if (isRunning.value) return
    isRunning.value = true

    if (containerEl) {
      container = containerEl
      isCustomContainer = true
      container.style.position = 'relative'
      container.style.overflow = 'hidden'
    } else {
      container = document.createElement('div')
      container.style.position = 'fixed'
      container.style.top = '0'
      container.style.left = '0'
      container.style.width = '100%'
      container.style.height = '100%'
      container.style.overflow = 'hidden'
      container.style.zIndex = '9999'
      container.style.pointerEvents = 'none'
      document.body.appendChild(container)
      isCustomContainer = false
    }

    addConfetto()
    frameId = requestAnimationFrame(animate)
  }

  function stopConfettiAnimation() {
    isRunning.value = false
    if (timerId) {
      clearTimeout(timerId)
      timerId = null
    }
  }

  onUnmounted(() => {
    isRunning.value = false
    if (frameId) {
      cancelAnimationFrame(frameId)
      frameId = null
    }
    if (timerId) {
      clearTimeout(timerId)
      timerId = null
    }
    cleanup()
  })

  return {
    startConfettiAnimation,
    stopConfettiAnimation,
    isRunning,
  }
}
